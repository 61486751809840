import { useSdk } from '~/sdk';


export function useBootstrap() {
  const { locale } = useI18n();
  const state = useState('bootstrap', () => {
    return {
      data: null,
      status: 'idle',
      error: null,
    };
  })

  async function refresh() {
    console.log('bootstrapping...');
    state.value.status = 'loading';
    state.value.error = null;

    const { data, status, error } = await useAsyncData(
      `bootstrap`,
      () => useSdk().commerce.bootstrap(),
      { watch: [locale], immediate: true },
    );

    state.value.data = data.value;
    state.value.status = status.value;
    state.value.error = error.value?.message ?? null;

  }

  return {
    ...toRefs(state.value),
    execute: refresh,
  };
}
